@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --background-start: 60 30% 98%;
    --background-end: 180 30% 98%;
    --nav-start: 60 30% 99%;
    --nav-end: 180 30% 99%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --background-start: 222.2 84% 4.9%;
    --background-end: 224 84% 5.9%;
    --nav-start: 222.2 84% 5.9%;
    --nav-end: 224 84% 6.9%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    background: linear-gradient(
      135deg,
      hsl(var(--background-start)) 0%,
      hsl(var(--background-end)) 100%
    );
    min-height: 100vh;
  }
}
